.ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;

  & .ul {
    list-style-type: circle;

    & .ul {
      list-style-type: square;
    }
  }
}
/* Handle margin-top of next adjacent element against this list element */
.ul,
.ol {
  & + p,
  & + a,
  & + ul,
  & + ol,
  & + code,
  & + div,
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 24px;
  }
}

.li {
  color: #274751;
}

.li > p {
  margin: 0;
}
