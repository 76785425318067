@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 1px solid var(--colorGrey100);
}

.footer {
  /* max-width: var(--contentMaxWidthPages); */
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 24px 32px;
  background-color: #F6F6F0;
}

.footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-top: 1px;
}

.linkList {
  list-style-type: none;
  padding-left: 12px;
}

.content {
  display: grid;
  justify-content: center;
  align-content: end;
  grid-auto-rows: auto;
  grid-gap: 32px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'logo'
    'details'
    'grid'
    'links';
}

.grid {
  grid-area: grid;
  display: grid;
  justify-content: center;
  align-content: start;
  grid-auto-rows: auto;
  grid-gap: 32px;
}

.gridCol1,
.gridCol2,
.gridCol3,
.gridCol4 {
  grid-template-columns: 1fr;
}

.detailsInfo {
  grid-area: links;
}

.slogan {
  font-size: 16px;
  line-height: 24px;
}

.sloganMobile {
  grid-area: details;
  padding-bottom: 24px;

  @media (min-width: 1024px) {
    display: none;
  }
}

.sloganDesktop {
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
}

.logo {
  grid-area: logo;
}

.copyright {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorGrey500);
  margin-top: 24px;
}

.icons {
  margin-top: 24px;

  @media (min-width: 1024px) {
    margin-top: 0px;
  }
}

.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;
  margin-bottom: 40px;

  &:hover {
    text-decoration: none;
  }
}

.logoImage {
  height: 44px;
  width: auto;
  max-width: 188px;
  object-fit: contain;
  object-position: center;
}

/* Custom Footer */

.footerContent {
  display: flex;
  padding-top: 50px;
}

.footerSlogan {
  font-family: 'Moret';
  font-size: 24px;
  line-height: 40px;
  padding-top: 25px;
  color: #274751;
}

.footerSignup {
  display: flex;
  height: 30px;
}

.footerSignup>input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.footerSignup button {
  white-space: nowrap;
  font-size: 12px;
  padding: 0 25px;
  height: 100%;
  background-color: #274751;
  border: 1px solid #274751;
  color: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #ffffff;
    color: #274751;
  }
}

@media (max-width: 1024px) {
  .footer {
    padding: 0;
  }

  .footerContent {
    display: block;
    padding-top: 25px;
    text-align: center;
  }

  .footerSlogan {
    font-size: 20px;
    line-height: 20px;
  }

  .footerSignup {
    margin: 0 auto;
  }
}

@media (min-width: 350px) {

  .gridCol2,
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) {

  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 768px) {
  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1024px) {
  .detailsInfo {
    grid-area: details;
  }

  .contentCol1 {
    /* 1/6 of space is for grid, and grid uses 1 column internally */
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }

  .contentCol2 {
    /* 2/6 of space is for grid, and grid splits it to 2 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }

  .contentCol3 {
    /* 3/6 of space is for grid, and grid splits it to 3 columns */
    grid-template-columns: 2fr 1fr 1fr 2fr;
    grid-template-areas:
      'logo . . .'
      'details . grid grid'
      'details . grid grid'
      'details . grid grid';
  }

  .contentCol4 {
    /* 4/6 of space is for grid, and grid splits it to 4 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details grid grid'
      'details grid grid'
      'details grid grid';
  }

  .gridCol1 {
    grid-template-columns: 1fr;
  }

  .gridCol2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridCol3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

li {
  color: #274751;
}

p {
  color: #274751;
}